.hero {
    position: relative;
    height: 100vh;
    display: grid;
    place-items: center;
    padding: 20px;
    background-color: var(--bg-dark);
}

.hero-image {
    position: absolute;
    inset: 0;
    overflow: hidden;
    z-index: 0;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
}

.hero-title {
    position: relative;
    margin: 0;
    @include fluid-type($min_width, $max_width, 32px, 90px);
    color: var(--text);
    z-index: 10;
    font-weight: 600;
    // margin-top: 200px;

    // @include media-breakpoint-down(lg) {
    //     margin-top: 175px;
    // }

    &.text-center {
        text-align: center;
    }

    &[data-shadow="text"] {
        text-shadow: 0 5px 20px rgba(0, 0, 0, .1);
    }

    &>span {
        color: white;
    }
}


.hero-detail {
    position: relative;
    background-color: var(--bg-dark);
    width: 100%;
    height: 100%;
    max-height: 800px;
    aspect-ratio: 3/2;
    
    &[data-size="full"] {
        height: 100vh;
        max-height: initial;
        aspect-ratio: initial;
        
        @include media-breakpoint-down(lg) {
            height: initial;
            aspect-ratio: 5/3;
        }
    }

    .image {
        position: absolute;
        inset: 0;
        overflow: hidden;
        z-index: 0;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
}

.hero-btn {
    position: absolute;
    z-index: 10;
    top: 40px;
    left: 50%;
    transform: translateX(-50%);

    @include media-breakpoint-down(lg) {
        top: 100px;
    }
}