.btn {
    display: inline-block;
    padding-inline: 2rem;
    padding-block: .75rem;
    color: #333;
    @include fluid-type($min_width, $max_width, 16px, 18px);
    font-weight: 600;
    background-color: var(--btn-primary);
    border-radius: 50vw;
    text-decoration: none;
    @include effect();

    &:hover {
        background-color: var(--btn-primary-hover);       
    }

    &--secondary {
        color: #333;
        background-color: white;
        
        &:hover {
            color: #333;
            background-color: #eee;       
        }
    }

    &--dark {
        background-color: var(--btn-dark);
        
        &:hover {
            background-color: var(--btn-dark-hover);       
        }
    }

    &--neutral {
        color: var(--title);
        background-color: var(--btn-neutral);
        
        &:hover {
            color: var(--title);
            background-color: var(--btn-neutral-hover);       
        }
    }

    &--simple {
        color: #333;
        background: none;
        padding: 0;

        &:hover {
            background: none;
        }
    }
}

.btn-bar {
    margin-block-start: 3rem;

    &--mb-4 {
        margin-block-end: 4rem;
    }
}