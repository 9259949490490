.transition-overlay {
    position: fixed;
    inset: 0;
    display: grid;
    grid-auto-flow: column;
    z-index: 9999;
    visibility: hidden;        
    
    &__tile {
        position: relative;
        width: 102%;
        height: 100%;
        transform: translateY(100%);
        background-color: var(--color-tertiary);
    }
}
