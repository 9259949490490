.table-default {
	overflow-x: auto;

	table {
		width: 100%;
		padding: 0;
		margin: 0;
		font-size: 16px;
		border-collapse: collapse;
	}

	tr {
		width: 100%;
		@include effect();
	}

	td,
	th {
		width: 100%;
		text-align: right;
		// min-width: 200px;
		padding: 1rem;
		vertical-align: bottom;

		&:first-child {
			text-align: left;
			padding-inline-start: 0.5rem;
		}

		@include media-breakpoint-down(sm) {
			padding: .5rem;
		}
	}

	thead {

		tr {
			border-bottom: 2px solid var(--text);
		}

		th {
			font-weight: 400;
			vertical-align: bottom;

			span {
				@include fluid-type($min_width, $max_width, 30px, 40px);
				color: var(--color-primary)
			}
		}
	}

	tbody {
		tr {
			border-bottom: 1px dashed var(--border);

			&:hover {
				background-color: #{rgba($primary, .2)};
			}
		}

		td {}
	}

	&__highlight {
		background-color: #{rgba($primary, .1)};
	}

	&__sum {
		background-color: #{rgba($primary, 1)};
	}

	&__title {
		@include fluid-type($min_width, $max_width, 16px, 20px);
		font-weight: bold !important;
	}

	&__meta {
		min-width: 100px !important;
		text-align: right !important;
	}

	&.table-eigenkapital {
		thead {
			tr {
				border-bottom: 0 none;

				&:first-child {
					th {
						padding-block-end: 0;
					}
				}

				&:not(:first-child) {
					border-bottom: 2px solid var(--text);
				}
			}
		}

	}
}