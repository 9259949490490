:root {
	--section-pt: 150px;
	--container-gap: 40px;
}

main {
	display: block; //IE9-11 Support
}

.section {
	padding-block: var(--section-pt);

	@include media-breakpoint-down(md) {
		--section-pt: 100px;
	}

	@include media-breakpoint-down(sm) {
		--section-pt: 50px;
	}

	&--first {
		@include media-breakpoint-down(md) {
			--section-pt: 150px;
		}

		@include media-breakpoint-down(sm) {
			--section-pt: 150px;
		}
	}

	&[data-bg="dark"] {
		background-color: var(--bg-dark);
	}

	&[data-bg="light"] {
		background-color: var(--bg-light);
	}

	&[data-size="min"] {
		min-height: 100vh;
	}

	&[data-section="story"] {
		position: relative;
		padding-block: inherit;
		display: grid;
		grid-template-columns: repeat(2, 1fr);

		.box {
			align-self: center;
			position: relative;
			margin-block-start: -100vh;
			margin-inline: auto;
			padding-inline: 5rem;
			padding-block: 25vh;
			width: 100%;
			max-width: 800px;
			z-index: 10;

			&[data-position='bottom'] {
				align-self: flex-end;
				padding-block: 0 3rem;
			}

			&--right {
				grid-column: 2/-1;
			}

			@include media-breakpoint-down(xl) {
				padding-inline: inherit;
				grid-column: span 2;
			}
		}

		.image {
			grid-column: 1 / -1;
			position: sticky;
			top: 0;
			height: 100vh;
			z-index: 5;
			overflow: hidden;
			//&::after {
			//	content: "";
			//	position: absolute;
			//	inset: 0;
			//	background-color: rgba(black, 0.75);
			//}

			img {
				object-fit: cover;
				object-position: center;
				height: 100%;
				width: 100%;
			}
		}

	}

	&[data-section="full"] {
		position: relative;
		display: grid;
		padding-block: initial;
		min-height: 100vh;

		@include media-breakpoint-down(md) {
			min-height: initial;
		}

		.image {
			position: absolute;
			inset: 0;
			overflow: hidden;
			z-index: 0;

			@include media-breakpoint-down(md) {
				position: relative;
				inset: inherit;
				aspect-ratio: 5/3;
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center;
			}
		}

		.box {
			align-self: center;
			padding-block: 100px;
			width: 100%;
			z-index: 10;

			@include media-breakpoint-down(md) {
				padding-block: 0;
			}

			&[data-position="start"] {
				align-self: flex-start;
			}

			&[data-position="end"] {
				align-self: flex-end;
			}
		}

		@include media-breakpoint-down(md) {
			.container {
				padding-right: 0;
				padding-left: 0;
			}
		}

		.content-box {
			padding: 3rem;
			background-color: white;

			@include media-breakpoint-down(md) {
				padding-inline: 0;
			}

			&[data-bg="dark"] {
				background-color: var(--bg-dark);
			}

			&[data-bg="light"] {
				background-color: var(--bg-light);
			}

			@include media-breakpoint-down(md) {
				padding-right: var(--container-gap);
				padding-left: var(--container-gap);
			}
		}
	}

	&[data-section="detail"] {
		position: relative;
		padding-block-start: inherit;

		.image {
			position: relative;
			margin-inline: auto;
			// max-width: 1920px;
			aspect-ratio: 2/1;

			&::after {
				content: "";
				position: absolute;
				inset: auto 0 0;
				width: 100vw;
				height: clamp(50px, 15vw, 150px);
				// background-color: rgb(38, 50, 56);
				background: linear-gradient(180deg, rgba(var(--bg-gradient-rgb), 0.9) 0%, rgba(var(--bg-gradient-rgb), 1) 90%);
			}

			img {
				object-fit: cover;
				object-position: center top;
				height: 100%;
				width: 100%;
			}
		}
	}

	&[data-section="half"] {
		position: relative;
		padding-block: inherit;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		min-height: 100vh;

		@include media-breakpoint-down(xl) {
			min-height: 75vh;
		}

		@include media-breakpoint-down(md) {
			grid-template-columns: repeat(1, 1fr);
			min-height: inherit;
		}

		.image {
			position: relative;
			width: 100%;
			height: 100%;
			overflow: hidden;

			@include media-breakpoint-down(md) {
				width: 100%;
				aspect-ratio: 1/1;
				order: 1;
			}

			img {
				object-fit: cover;
				object-position: center top;
				height: 100%;
				width: 100%;
			}
		}

		.box {
			margin-inline: auto;
			padding-block: 2rem;
			max-width: 640px;
			align-self: center;

			@include media-breakpoint-down(md) {
				order: 2;
				padding-block-end: 4rem;
			}
		}

		.box-p-lg {
			padding-block: 8rem;
		}
	}
}

.section-item {
	&:not(:last-child) {
		margin-bottom: 7rem;

		@include media-breakpoint-down(md) {
			margin-bottom: 5.5rem;
		}

		@include media-breakpoint-down(sm) {
			margin-bottom: 4rem;
		}
	}
}

.container-big {
	margin-inline: auto;
	max-width: 1680px;
}

.container,
.container-big,
.container-fluid {
	padding-right: var(--container-gap);
	padding-left: var(--container-gap);

	@include media-breakpoint-down(sm) {
		--container-gap: 20px;
	}
}

.content-box {
	padding: 3rem;
	background-color: white;
}

.content {

	a {
		&:not(.btn) {
			color: var(--color-primary);
			text-decoration: underline;
		}
	}

	&>h2,
	&>h3,
	&>h4,
	&>h5 {
		&:not(:first-child) {
			margin-block-start: 3rem;
		}
	}

	ul {
		@include media-breakpoint-down(md) {
			margin-top: 1rem;
		}
	}

	ul>li {
		list-style-type: none;
		position: relative;
		padding-left: 2rem;
		margin-bottom: 1rem;

		@include media-breakpoint-down(md) {
			margin-bottom: .5rem;
			font-size: 16px;
		}

		&::before {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: 0;
			content: "";
			width: 20px;
			height: 20px;
			background-color: var(--color-secondary);
			mask-image: url("../img/icon/circle-check-solid.svg");
			mask-repeat: no-repeat;
			mask-position: center;
			mask-size: 20px;
		}
	}

	&-center {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	&[data-content="white"] {

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			--title: white;
		}

		--text: white;
	}

	&[data-content="invert"] {

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			--title: white;
		}

		.title-info {
			--text: white;
		}

		--text: white;
	}

	&[data-content="white"],
	&[data-content="invert"] {
		.title-primary {
			@extend .title-primary;
		}

		.title-secondary {
			@extend .title-secondary;
		}

		a {
			&:not(.btn) {
				color: var(--color-primary-light);
			}
		}
	}
}

.content-item {
	&:not(:last-child) {
		margin-block-end: 2rem;
	}

	&-center {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}
}

.col-md-content {
	@include media-breakpoint-down(md) {
		margin-block-end: 2rem;
	}
}

.col-md-order {
	@include media-breakpoint-down(md) {
		order: -1;
	}
}

.col-lg-content {
	@include media-breakpoint-down(lg) {
		margin-block-end: 2rem;
	}
}

.z-10 {
	z-index: 10;
}

.flex-sign {
	margin-block-start: 2rem;
	display: flex;
	flex-wrap: wrap;
	gap: 1rem 3rem;
}

.teaser-box {
	background-color: white;
	padding: 2rem;
	height: 100%;

	.content {
		display: flex;
		flex-direction: column;
		height: 100%;
	}

	.btn-bar {
		margin-block-start: auto;
	}
}

blockquote {
	padding: 0;
	margin-block: 2rem;
	margin-inline: initial;
	font-weight: 600;
	@include fluid-type($min_width, $max_width, 18px, 24px);
}

.grid-meta {
	display: grid;
	gap: 3rem;
	grid-template-columns: repeat(3, minmax(0, 1fr));

	@include media-breakpoint-down(xl) {
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}

	@include media-breakpoint-down(md) {
		grid-template-columns: repeat(1, minmax(0, 1fr));
	}
}

@supports (-webkit-hyphens:none) {
	.grid-inzahlen {
		opacity: 1 !important;
		transform: translate(0px) !important;
	}

}

.grid-inzahlen {
	$this: &;
	display: grid;
	margin-block-start: 4rem;
	grid-template-columns: repeat(3, minmax(0, 1fr));
	grid-template-rows: auto;
	grid-template-areas:
		"item1 item2 item6"
		"item3 item4 item6"
		"item5 item5 item6";

	@include media-breakpoint-down(lg) {
		grid-template-columns: repeat(2, minmax(0, 1fr));
		grid-template-areas:
			"item1 item2"
			"item3 item4"
			"item5 item5"
			"item6 item6";
	}

	@include media-breakpoint-down(sm) {
		grid-template-columns: repeat(1, minmax(0, 1fr));
		grid-template-areas:
			"item1"
			"item2"
			"item3"
			"item4"
			"item5"
			"item6";
	}



	&__item {
		padding: 1rem;
		text-align: center;

		&:nth-child(1) {
			grid-area: item1;
			border-right: 1px dashed var(--border);
			border-bottom: 1px dashed var(--border);

			@include media-breakpoint-down(sm) {
				border-right: initial;
			}

			.counter-grid__icon {
				width: 80px;
				height: auto;
				margin-inline: auto;
				margin-block-end: 0;
			}

			.counter-grid__number-1,
			.counter-grid__number-group-1 {
				font-weight: 600;
				@include fluid-type($min_width, $max_width, 25px, 50px);
			}

			.counter-grid__number-2,
			.counter-grid__number-group-2 {
				font-weight: 600;
				@include fluid-type($min_width, $max_width, 16px, 24px);
			}

			.counter-grid__number-3,
			.counter-grid__number-group-3 {
				font-weight: 600;
				@include fluid-type($min_width, $max_width, 16px, 24px);
			}
		}

		&:nth-child(2) {
			grid-area: item2;
			border-bottom: 1px dashed var(--border);

			.counter-grid__icon {
				width: 140px;
				height: auto;
				margin-inline: auto;
				margin-block-end: 0;
			}

			.counter-grid__number,
			.counter-grid__number-group {
				font-weight: 600;
				@include fluid-type($min_width, $max_width, 25px, 50px);
			}
		}

		&:nth-child(3) {
			grid-area: item3;
			border-right: 1px dashed var(--border);
			border-bottom: 1px dashed var(--border);

			@include media-breakpoint-down(sm) {
				border-right: initial;
			}

			.counter-grid__icon {
				width: 100px;
				height: auto;
				margin-inline: auto;
				margin-block-end: 0;
			}

			.counter-grid__number,
			.counter-grid__number-group {
				font-weight: 600;
				@include fluid-type($min_width, $max_width, 16px, 24px);
			}
		}

		&:nth-child(4) {
			grid-area: item4;
			border-bottom: 1px dashed var(--border);

			.counter-grid__icon {
				width: 180px;
				height: auto;
				margin-inline: auto;
				margin-block-end: 0;
			}

			.counter-grid__number,
			.counter-grid__number-group {
				font-weight: 600;
				@include fluid-type($min_width, $max_width, 16px, 24px);
			}
		}

		&:nth-child(5) {
			grid-area: item5;
			grid-column: 1 / 3;
		}

		&:nth-child(6) {
			grid-area: item6;
			border-left: 1px dashed var(--border);

			@include media-breakpoint-down(lg) {
				border-top: 1px dashed var(--border);
				border-left: 0;
			}
		}

		.counter-grid {
			display: flex;
			flex-direction: column;
			gap: initial;
		}
	}

	&__img {
		width: 100%;
		height: auto;
	}

	&__title {
		@include fluid-type($min_width, $max_width, 12px, 16px);
		font-weight: 600;
		text-transform: uppercase;
		hyphens: auto;
	}

	&__icon {
		display: inline-block;
		width: 1em;
		height: 1em;
		background-position: center;
		background-size: contain;
		background-repeat: no-repeat;

		&--star {
			background-image: url('../img/numbers/Stern.svg');
		}

		&--globe {
			background-image: url('../img/numbers/Globus.svg');
		}

		&--globe-off {
			background-image: url('../img/numbers/Globus_grau.svg');
		}

		&--a {
			width: .75em;
			height: .75em;
			background-image: url('../img/numbers/A.svg');
		}
	}

	&__award {
		display: flex;
		margin-block: 1rem 2rem;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		span {
			display: flex;
			gap: .5em;
			align-items: center;
		}
	}

	&__star {
		display: flex;
		justify-content: center;
		gap: .6em;
		margin-block-end: .75rem;
	}

	&__globe {
		display: flex;
		justify-content: center;
		gap: .6em;
		margin-block-end: .5rem;
	}

	&__group {
		span {
			display: inline-block;
			margin-block-end: .5rem;
			@include fluid-type($min_width, $max_width, 12px, 16px);
			font-weight: 600;
		}
	}

	&__group-item {
		position: relative;

		&:not(:last-child) {
			padding-block-end: 3em;

			&::after {
				content: "";
				position: absolute;
				inset: auto auto 1.5em auto;
				width: 120px;
				height: 1px;
				border-top: 1px dashed var(--border);
				translate: -50% 0;
			}
		}

	}
}

@media (min-width: 768px) {

	.counter-grid.float-right {
		float: right;

		width: 50%;
	}
}

.counter-grid.float-right {
	margin-block-end: 2rem;
	padding: 0 calc(var(--bs-gutter-x) * 0.5);
}